import React from 'react'
import { Pricing } from '../../components'


const Home = () => {
    return (
        <>
         
          <Pricing />   
        </>
    )
}

export default Home